import React from 'react';
import { Link } from 'react-router-dom';
import * as s from "./styles/globalStyles";

function Home() {
  return (
    <div style={{
      textAlign: "center",
      margin: "0 auto",
      background: "none",
      color: "#333",
      fontFamily: "Nunito, sans-serif",
    }}>
      <img style={{ display: "block", paddingTop: "24px", maxHeight: "160px", margin: "0 auto" }} src="./config/images/logo.png" alt="song" />
      <div style={{ margin: "16px" }}></div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "40px" }}>
        <div style={{ flex: "1 0 200px", maxWidth: "300px" }}>
          <s.Logo src="./config/images/1.jpg" alt="song" />
          <s.Container style={{ margin: "8px 0px 0px", fontSize: "16px", height: "2rem" }} ai={"center"} jc={"center"}>
            Little While ~じゃぁ、またね~
          </s.Container>
          <s.Container style={{ margin: "7px 0px", fontSize: "15px" }} ai={"center"} jc={"center"}>
            ニッコー閣下
          </s.Container>
          <audio controls controlslist="nodownload" src="./config/listen/1.mp3"></audio>
          <s.Container style={{ margin: "16px 0px 16px", fontSize: "14px" }} ai={"center"} jc={"center"}>
            <Link to="/song01">
              <button style={{
                padding: "10px 20px",
                fontSize: "14px",
                backgroundColor: "#333",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}>
                購入ページへ
              </button>
            </Link>
          </s.Container>
        </div>

        <div style={{ flex: "1 0 200px", maxWidth: "300px" }}>
          <s.Logo src="./config/images/2.jpg" alt="song" />
          <s.Container style={{ margin: "8px 0px 0px", fontSize: "16px", height: "2rem" }} ai={"center"} jc={"center"}>
            おもひで（DragonChop cover)
          </s.Container>
          <s.Container style={{ margin: "7px 0px", fontSize: "15px" }} ai={"center"} jc={"center"}>
            ニッコー閣下
          </s.Container>
          <audio controls controlslist="nodownload" src="./config/listen/2.mp3"></audio>
          <s.Container style={{ margin: "16px 0px 16px", fontSize: "14px" }} ai={"center"} jc={"center"}>
            <Link to="/song02">
              <button style={{
                padding: "10px 20px",
                fontSize: "14px",
                backgroundColor: "#333",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}>
                購入ページへ
              </button>
            </Link>
          </s.Container>
        </div>

        <div style={{ flex: "1 0 200px", maxWidth: "300px" }}>
          <s.Logo src="./config/images/3.jpg" alt="song" />
          <s.Container style={{ margin: "8px 0px 0px", fontSize: "16px", height: "2rem" }} ai={"center"} jc={"center"}>
            ミマモリタイ（NFT Original Band Edition)
          </s.Container>
          <s.Container style={{ margin: "7px 0px", fontSize: "15px" }} ai={"center"} jc={"center"}>
            DRAGON CHOP & RHIME手裏剣
          </s.Container>
          <audio controls controlslist="nodownload" src="./config/listen/3.mp3"></audio>
          <s.Container style={{ margin: "16px 0px 16px", fontSize: "14px" }} ai={"center"} jc={"center"}>
            <Link to="/song03">
              <button style={{
                padding: "10px 20px",
                fontSize: "14px",
                backgroundColor: "#333",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}>
                購入ページへ
              </button>
            </Link>
          </s.Container>
        </div>

        <div style={{ flex: "1 0 200px", maxWidth: "300px" }}>
          <s.Logo src="./config/images/4.jpg" alt="song" />
          <s.Container style={{ margin: "8px 0px 0px", fontSize: "16px", height: "2rem" }} ai={"center"} jc={"center"}>
            いとなみ
          </s.Container>
          <s.Container style={{ margin: "7px 0px", fontSize: "15px" }} ai={"center"} jc={"center"}>
            ピクアン
          </s.Container>
          <audio controls controlslist="nodownload" src="./config/listen/4.wav"></audio>
          <s.Container style={{ margin: "16px 0px 16px", fontSize: "14px" }} ai={"center"} jc={"center"}>
            <Link to="/song04">
              <button style={{
                padding: "10px 20px",
                fontSize: "14px",
                backgroundColor: "#333",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}>
                購入ページへ
              </button>
            </Link>
          </s.Container>
        </div>
      </div>


      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px", margin: "48px 0px 0px", color: "#fff" }}>
        <div style={{ margin: "10px" }}>
          <a href="" style={{ color: "#333" }}>特定商取引法に基づく表記</a>
        </div>
        <div style={{ margin: "10px" }}>
          <a href="" style={{ color: "#333" }}>個人情報の取扱いについて</a>
        </div>
      </div>

      <div style={{ margin: "32px 0px" }}></div>
    </div>
  )
}

export default Home;