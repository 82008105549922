import styled from "styled-components";

export const Container = styled.div`
  font-family: Nunito,sans-serif;
  color: #222;
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(175, 223, 228, 0);
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const Logo = styled.img`
  max-height: 300px;
  max-width: 300px;
  margin: 0 auto;
`;

export const Songs = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;
  margin-bottom: 100px;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
  }
`;

// mint -------------------------------
export const MintPhaseResponsive = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 8px;
  @media (max-width: 4200px) { //ここでミントエリアのレスポンシブ調整
    display: block;
  }
`;

export const StyledButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #333;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  width: 256px;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  border-radius: 100%;
  border: solid 1px rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0);
  padding: 6px;
  margin: 10px;
  font-size: 12px;
  color: #fff;
  width: 26px;
  height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 2px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 2px 4px 0px -2px rgba(250, 250, 250, 0.3);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover{
    transform: translate3d(0, 2px, 0);
    box-shadow: none;
  }
`;

export const StyledButtonClamed = styled.button`
  border: solid 1px rgba(255, 255, 255, 0.5);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  width: 256px;
  cursor: pointer;
`;

export const MintZoneBottun = styled.div`
  padding: 6px 0px 24px;
  @media (max-width: 720px) {
    margin: 0 auto;
    max-width: 720px; //ここでミントエリアのレスポンシブ調整
    padding: 8px 0px 36px;
  }
`;

// text
export const TextMsg = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
`;

export const WalletAddress = styled.p`
  margin: 4px;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
`;

export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;