import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Song01 from './Song01';
import Song02 from './Song02';
import Song03 from './Song03';
import Song04 from './Song04';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/song01" element={<Song01 />} />
        <Route path="/song02" element={<Song02 />} />
        <Route path="/song03" element={<Song03 />} />
        <Route path="/song04" element={<Song04 />} />
      </Routes>
    </Router>
  );
}

export default App;
