import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import { Link } from 'react-router-dom';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


function Song02() { // トークンIDごとに変更
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [pubSaleStart, setPubSaleStart] = useState(false);
  const mintAmountPub = 1;
  const tokenId = 2; // 曲ごとにセット
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [balance, setBalance] = useState(0);

  // config
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkPubSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .saleStart(tokenId)
        .call()
        .then((receipt) => {
          setPubSaleStart(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkBalance = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .totalSupply(tokenId)
        .call()
        .then((receipt) => {
          setBalance(receipt);
          dispatch(fetchData(blockchain.account));
        });
        console.log(balance);
    }
  };

  const claimNFTs = async () => {
    // Mintに必要なコストを設定
    let cost = BigInt("20000000000000000000"); // 本番時変更
    let totalCostWei = cost * BigInt(mintAmountPub);

    // ウォレットの残高を取得
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = BigInt(balance); // BigIntに変換

    if (balanceWei < totalCostWei) {
      // ウォレットの残高が足りない場合、ユーザーに通知
      alert("ウォレットの残高が不足しています");
      return;
    }

    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.mintBSR(blockchain.account, tokenId, mintAmountPub);
    setFeedback("Mint now...");
    setClaimingNft(true);

    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei.toString(), // BigIntを文字列に変換して渡す
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback("Mint success!");
      setClaimingNft(false);
      checkBalance();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("Please try again.");
      setClaimingNft(false);
    }
  };


  useEffect(() => {
    getData();
    getConfig();
    checkPubSaleStart();
    checkBalance();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkPubSaleStart();
    checkBalance();
  }, [blockchain.account]);

  return (
    <div style={{
      textAlign: "center",
      margin: "0 auto",
      background: "none",
      color: "#333",
      fontFamily: "Nunito, sans-serif",
    }}>

      <img style={{ display: "block", paddingTop: "24px", maxHeight: "160px", margin: "0 auto" }} src="./config/images/logo.png" alt="song" />
      <s.Logo src="./config/images/2.jpg" alt="song" />
      <s.Container style={{ margin: "16px 0px 0px", fontSize: "20px" }} ai={"center"} jc={"center"}>
        おもひで（DragonChop cover)
      </s.Container>
      <s.Container style={{ margin: "7px 0px 0px", fontSize: "15px" }} ai={"center"} jc={"center"}>
        ニッコー閣下
      </s.Container>
      <s.Container style={{ margin: "16px 0px 16px", fontSize: "14px" }} ai={"center"} jc={"center"}>
        20POL (クレカ決済対応)
      </s.Container>
      <s.MintZoneBottun>
        <s.Container flex={1} ai={"center"} style={{ maxHeight: "240px" }}>
          <>
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.StyledButton
                  style={{ marginBottom: "20px" }}
                  onClick={() => {
                    window.open(`https://paypiement.xyz/projects/ff582149-1abe-4d30-986d-92939cdad8af?tokenId=${tokenId}&quantity=1`);
                  }}
                >
                  日本円で購入
                </s.StyledButton>
                <s.StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  POLで購入
                </s.StyledButton>

                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.TextMsg style={{ fontSize: "10px" }}>
                      {blockchain.errorMsg}
                    </s.TextMsg>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <></>
            )}
          </>
        </s.Container>

        <s.MintPhaseResponsive>
          <div>
            <>
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  {blockchain.errorMsg !== "" ? (
                    <></>
                  ) : null}
                </s.Container>
              ) : (
                <div>

                  {pubSaleStart ? (
                    <>
                      {balance < 20 ? ( // 曲ごとにセット
                        <>
                          <div>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>

                              <s.StyledButton
                                disabled={claimingNft ? 1 : 0}//claimingNftsがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                {claimingNft ? "Processing..." : "Mint"}
                              </s.StyledButton>
                            </s.Container>
                          </div>
                        </>
                      ) : (
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.StyledButtonClamed>
                              Sold out
                            </s.StyledButtonClamed>
                          </s.Container>

                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.StyledButtonClamed>
                          Please wait
                        </s.StyledButtonClamed>
                      </s.Container>
                    </>
                  )}
                  <s.SpacerXSmall />
                  <s.WalletAddress>
                    WalletAddress : {truncate(blockchain.account, 4)}{truncate(blockchain.account.slice(-4), 4)}
                  </s.WalletAddress>

                </div>
              )}
            </>
            <s.TextMsg>{feedback}</s.TextMsg>
          </div>
        </s.MintPhaseResponsive>
      </s.MintZoneBottun>
      <div style={{ fontSize: "14px", marginTop: "12px" }}>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          ホームに戻る
        </Link>
      </div>
      <div style={{ marginTop: "32px" }}></div>

    </div>
  )
}

export default Song02; // トークンIDごとに変更